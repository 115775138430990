var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    _vm._b(
      { staticClass: "card-button text-left", on: { click: _vm.onClick } },
      "b-button",
      _vm.btnAttr,
      false
    ),
    [
      !!_vm.icon
        ? _c(
            "div",
            _vm._b(
              { staticClass: "rounded-circle bg-light icon-container" },
              "div",
              _vm.iconCircleAttr,
              false
            ),
            [
              _c(
                _vm.iconComponent,
                _vm._b(
                  {
                    tag: "component",
                    staticClass: "text-dark icon-size",
                    attrs: { icon: _vm.icon },
                  },
                  "component",
                  _vm.iconAttr,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", [
        _c(
          "p",
          {
            staticClass: "h5 m-0 text-white",
            class: { "text-dark": _vm.isTextDark },
          },
          [
            _vm.textShortcuts.length > 0
              ? _c("e-shortcut", {
                  attrs: {
                    "badge-attr": _vm.isTextDark
                      ? { variant: "dark" }
                      : { variant: "light" },
                    shortcuts: _vm.textShortcuts,
                  },
                })
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.textTitle) + " "),
          ],
          1
        ),
        _c(
          "p",
          {
            staticClass: "m-0 text-white",
            class: { "text-dark": _vm.isTextDark },
          },
          [
            _vm.textSubtitle
              ? [_vm._v(" " + _vm._s(_vm.textSubtitle) + " ")]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }