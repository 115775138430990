<template>
  <b-button
    class="card-button text-left"
    v-bind="btnAttr"
    @click="onClick"
  >
    <div
      v-if="!!icon"
      class="rounded-circle bg-light icon-container"
      v-bind="iconCircleAttr"
    >
      <component
        :is="iconComponent"
        :icon="icon"
        class="text-dark icon-size"
        v-bind="iconAttr"
      />
    </div>
    <div>
      <p
        class="h5 m-0 text-white"
        :class="{ 'text-dark': isTextDark }"
      >
        <e-shortcut
          v-if="textShortcuts.length > 0"
          :badge-attr="isTextDark ? { variant: 'dark' } : { variant: 'light' }"
          :shortcuts="textShortcuts"
        />
        {{ textTitle }}
      </p>
      <p
        class="m-0 text-white"
        :class="{ 'text-dark': isTextDark }"
      >
        <template v-if="textSubtitle">
          {{ textSubtitle }}
        </template>
      </p>
    </div>
  </b-button>
</template>

<script>
import { BButton, BIcon } from 'bootstrap-vue'
import EShortcut from './EShortcut.vue'

export default {
  components: { BButton, EShortcut, BIcon },

  props: {
    /**
     * Nome do componente para ícone
     * Opções válidas: FeatherIcon, BIcon
     */
    iconComponent: {
      type: String,
      default: 'FeatherIcon',
    },
    btnAttr: {
      type: Object,
      default() {
        return {
          variant: 'dark',
        }
      },
    },
    textTitle: {
      type: String,
      default: '',
    },
    textSubtitle: {
      type: String,
      default: '',
    },
    textShortcuts: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: '',
    },
    iconAttr: {
      type: Object,
      default() {
        return {
          size: '20',
        }
      },
    },
    iconCircleAttr: {
      type: Object,
      default() {
        return {}
      },
    },
    isTextDark: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.card-button {
  min-height: 70px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.icon-size {
  font-size: 25px !important;
}

.icon-container {
  padding: 10px;

  @media (min-width: 1500px) {
    padding: 15px;
  }
}
</style>
