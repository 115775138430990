var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    _vm._b(
      {
        attrs: { disabled: _vm.$attrs.disabled || _vm.busy },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      "b-button",
      _vm.$attrs,
      false
    ),
    [
      _vm.icon && !_vm.busy
        ? _c(_vm.iconComponent, {
            tag: "component",
            attrs: { icon: _vm.icon, "v-bind": _vm.iconAttr },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }