<template>
  <div class="method-line w-100 d-flex justify-content-between align-items-center p-1">
    <div
      class="flex-grow-1 mx-1"
      :class="{ cancelled: isCancelled }"
    >
      <p class="m-0">
        {{ receipt.paymentMethod.name }}

        <span v-if="receipt.cardBrand">
          (
          <template v-if="receipt.paymentMethodInterface">
            {{ paymentMethodInterfaceLabel[receipt.paymentMethodInterface] || '-' }}
          </template>

          <template v-if="receipt.cardBrand">
            {{ receipt.cardBrand || '-' }}
          </template>
          )
        </span>
      </p>
      <p
        v-if="receipt.nsu"
        class="m-0"
      >
        <b>NSU: </b> {{ receipt.nsu || '-' }}
      </p>
    </div>

    <div
      class="mx-1 text-right"
      :class="{ cancelled: isCancelled }"
    >
      <p class="m-0">
        {{ value | currency }}
      </p>
      <p
        v-if="receipt.change > 0"
        class="m-0"
        :class="{
          'text-primary': !isCancelled,
          cancelled: isCancelled,
        }"
      >
        <b>{{ $t('Troco') }}: </b> {{ receipt.change | currency }}
      </p>
    </div>

    <div v-if="hasActions">
      <p class="mb-0 d-flex">
        <e-button
          v-if="isTEF"
          :id="`${$attrs.id}-btn_print`"
          :title="$t('PAY_BOX_SALE.TEF.PRINT_TEF')"
          variant="primary"
          icon="printer-fill"
          size="sm"
          @click="onPrint"
        />
        <e-button
          v-if="showBtnCanceled && !isCancelled && !isChoppGiftCardConversion"
          :id="`${$attrs.id}-btn_delete`"
          :title="isTEF ? $t('PAY_BOX_SALE.TEF.CANCEL_TEF') : $t('Excluir pagamento')"
          style="margin-left: 5px"
          tabindex="-1"
          variant="danger"
          size="sm"
          icon="trash"
          @click="onDelete"
        />
      </p>
    </div>
  </div>
</template>

<script>
import { paymentTypes } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import { mapGetters } from 'vuex'

export default {
  components: { EButton },
  mixins: [paymentTypes],
  props: {
    receipt: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    hasActions: {
      type: Boolean,
      default: true,
    },
    isCancelled: {
      type: Boolean,
      default: false,
      required: false,
    },
    showBtnCanceled: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters('pages/pdv/payBox', ['isChoppGiftCardConversion']),
    isTEF() {
      return this.receipt.paymentMethodInterface === this.paymentMethodInterfaceEnum.TEF
    },
  },

  methods: {
    onDelete() {
      this.$emit('click-delete')
    },
    onPrint() {
      this.$emit('click-print')
    },
  },
}
</script>

<style lang="scss" scoped>
.method-line {
  background: #c4c4c4;
  margin-bottom: 5px;
  color: var(--dark);
  font-weight: 600 !important;
}

.cancelled {
  text-decoration: line-through;
  color: var(--danger);
}
</style>
