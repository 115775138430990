var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "method-line w-100 d-flex justify-content-between align-items-center p-1",
    },
    [
      _c(
        "div",
        {
          staticClass: "flex-grow-1 mx-1",
          class: { cancelled: _vm.isCancelled },
        },
        [
          _c("p", { staticClass: "m-0" }, [
            _vm._v(" " + _vm._s(_vm.receipt.paymentMethod.name) + " "),
            _vm.receipt.cardBrand
              ? _c(
                  "span",
                  [
                    _vm._v(" ( "),
                    _vm.receipt.paymentMethodInterface
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.paymentMethodInterfaceLabel[
                                  _vm.receipt.paymentMethodInterface
                                ] || "-"
                              ) +
                              " "
                          ),
                        ]
                      : _vm._e(),
                    _vm.receipt.cardBrand
                      ? [
                          _vm._v(
                            " " + _vm._s(_vm.receipt.cardBrand || "-") + " "
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" ) "),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm.receipt.nsu
            ? _c("p", { staticClass: "m-0" }, [
                _c("b", [_vm._v("NSU: ")]),
                _vm._v(" " + _vm._s(_vm.receipt.nsu || "-") + " "),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mx-1 text-right",
          class: { cancelled: _vm.isCancelled },
        },
        [
          _c("p", { staticClass: "m-0" }, [
            _vm._v(" " + _vm._s(_vm._f("currency")(_vm.value)) + " "),
          ]),
          _vm.receipt.change > 0
            ? _c(
                "p",
                {
                  staticClass: "m-0",
                  class: {
                    "text-primary": !_vm.isCancelled,
                    cancelled: _vm.isCancelled,
                  },
                },
                [
                  _c("b", [_vm._v(_vm._s(_vm.$t("Troco")) + ": ")]),
                  _vm._v(
                    " " + _vm._s(_vm._f("currency")(_vm.receipt.change)) + " "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm.hasActions
        ? _c("div", [
            _c(
              "p",
              { staticClass: "mb-0 d-flex" },
              [
                _vm.isTEF
                  ? _c("e-button", {
                      attrs: {
                        id: _vm.$attrs.id + "-btn_print",
                        title: _vm.$t("PAY_BOX_SALE.TEF.PRINT_TEF"),
                        variant: "primary",
                        icon: "printer-fill",
                        size: "sm",
                      },
                      on: { click: _vm.onPrint },
                    })
                  : _vm._e(),
                _vm.showBtnCanceled &&
                !_vm.isCancelled &&
                !_vm.isChoppGiftCardConversion
                  ? _c("e-button", {
                      staticStyle: { "margin-left": "5px" },
                      attrs: {
                        id: _vm.$attrs.id + "-btn_delete",
                        title: _vm.isTEF
                          ? _vm.$t("PAY_BOX_SALE.TEF.CANCEL_TEF")
                          : _vm.$t("Excluir pagamento"),
                        tabindex: "-1",
                        variant: "danger",
                        size: "sm",
                        icon: "trash",
                      },
                      on: { click: _vm.onDelete },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }