<template>
  <b-button
    v-bind="$attrs"
    :disabled="$attrs.disabled || busy"
    @click="$emit('click')"
  >
    <component
      :is="iconComponent"
      v-if="icon && !busy"
      :icon="icon"
      :v-bind="iconAttr"
    />
  </b-button>
</template>

<script>
import { BButton, BIcon } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BIcon,
  },

  props: {
    /**
     * Nome do componente para ícone
     * Opções válidas: FeatherIcon, BIcon
     */
    iconComponent: {
      type: String,
      default: 'FeatherIcon',
    },
    busy: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    iconAttr: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
}
</script>
